import React from 'react'

function AboutWidget({children, headImage}) {
    return (
        <>
        <img className='about-head' src={headImage}/>
        {children}
        </>
    )
}


function About(){
    return(
        <div></div>
    )
  return (
    <div className='inline-content'>
      <div id="page-about" className="page-about">
       <AboutWidget headImage={require("../resources/ABOUT.png")}>
            <div className='about-desc'>
            <p>I am a 17-year-old <span style={{color:"#ff9677"}}>game developer</span> who has been actively involved in <span style={{color:"#ff9677"}}>game development</span> since 2016. With five years of experience using <span style={{color:"#ff9677"}}>Gamemaker</span> and a few years of experience using <span style={{color:"#ff9677"}}>Godot</span>, I am eager to improve my <span style={{color:"#ff9677"}}>C++</span> knowledge and explore 3D game development using <span style={{color:"#ff9677"}}>Unreal Engine 5</span> in the future. I am also interested in learning other programming languages, such as <span style={{color:"#ff9677"}}>Python</span></p>
<p>I have participated in numerous <span style={{color:"#ff9677"}}>game jam events</span> and have created several games. Besides <span style={{color:"#ff9677"}}>game development</span>, I enjoy watching movies and series in my free time. I have recently started working as a <span style={{color:"#ff9677"}}>freelance game developer</span> since the end of 2021. If you wish to contact me, please feel free to send me a message via <a style={{color:"#5865f2"}} target='_blank' href='https://discordapp.com/users/474558346960044032'>Discord</a> or <a target='_blank' style={{color:"#62b3f2"}} href='https://twitter.com/Eneskp3441'>Twitter</a>.</p>
{/* 
             <table className='skillTable'>
                <thead>
                    <tr>
                    <th>Languages</th>
                    <th>Engines/Frameworks</th>
                    <th>Tools</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>GML</td>
                    <td>Gamemaker</td>
                    <td>Photoshop</td>
                    </tr>
                    <tr>
                    <td>Python</td>
                    <td>Gamemaker</td>
                    <td>Photoshop</td>
                    </tr>
                    <tr>
                    <td>GDScript</td>
                    <td>Godot</td>
                    </tr>
                    <tr>
                    <td>JAVA / JavaScript</td>
                    <td>Godot</td>
                    </tr>
                    <tr>
                    <td>HTML, CSS</td>
                    <td>Unreal Engine 5</td>
                    </tr>
                    <tr>
                    <td>C++</td>
                    </tr>
                </tbody>
                </table> */}
                {/* <p style={{fontSize:".5em", color:"rgba(255,255,255,.5)"}}>This table shows the tools that I will start in the future / Beginner Level / Actively used.</p> */}
            </div>
      </AboutWidget>
      </div>
    </div>
    )
}

export default About