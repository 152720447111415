import React from 'react'
import { useState} from "react";

function NavBar(){
    const [scrollPos, setScrollPos] = useState(0);
    const handleScroll = (e) => {
        const scrollY = e.target.scrollTop;
        setScrollPos(scrollY);
        
        const circularBar = document.querySelector(".circular-navbar");
        const topBg = document.querySelector(".topBg");
        if (scrollY > 100) {
            circularBar.style.transform = "translate(-50%, -50%) scale(.6)"
            topBg.style.transform = "translateY(-140px)"
        } else {
            circularBar.style.transform = "translate(-50%, -50%) scale(1)"
            topBg.style.transform = "translateY(0)"
        }

    }
    setTimeout(() => {
        const mainContent = document.querySelector('.main-content');
        if (mainContent) { mainContent.addEventListener('scroll', handleScroll); }
    }, 100)
    


  return (
    <>
        <svg className="topBg" style={{filter:"drop-shadow(0px 2px 12px black)"}}></svg>
        <div className="circular-navbar">
        <span><a>3441</a></span>
        <a onClick={() => {
            const mainContent = document.querySelector('.main-content');
            mainContent.scrollTo({ top: 0, behavior: 'smooth' });
        }} className="menu-item home" href="#">HOME</a>

        <a className="menu-item games" href="#" onClick={() => {
            const mainContent = document.querySelector('.main-content');
            const targetElement = document.querySelector('#published_games');
            mainContent.scrollTo({ top: targetElement.getBoundingClientRect().top + mainContent.scrollTop - 256, behavior: 'smooth' });
        }}>GAMES</a>
        <a className="menu-item about" href="#" onClick={() => {
            const mainContent = document.querySelector('.main-content');
            const targetElement = document.querySelector('#page-about');
            mainContent.scrollTo({ top: targetElement.getBoundingClientRect().top + mainContent.scrollTop - 256, behavior: 'smooth' });
        }}>ABOUT</a>
        </div>
    </>
    )
}

export default NavBar