import logo from './logo.svg';
import './App.css'; 
import './Custom/page-home.css'; 
import './Custom/page-games.css';
import './Custom/page-about.css';
import MainHome from "./components/page-home"
import MainGame from "./components/page-games"
import NavBar from './components/navbar';
import About from './components/page-about';


function App() {
  
  return (
    <div id="apptop" className="App">
       <div className="main-container">
       <div class="area" >
      <ul class="circles">
        <li/><li/><li/><li/><li/><li/><li/><li/><li/><li/><li/>
      </ul>
      </div >
        <div className="main-content">
          <NavBar/>
          <div className="content">
            <MainHome/>
            <About/>
            <MainGame/>
            
          </div>

        </div>
       </div>
    </div>
  );
}

export default App;
