import React from 'react'

import { AiOutlineTwitter } from "react-icons/ai";
import { FaItchIo } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";
import { motion } from "framer-motion";


function SocialIcon({socialName, link, iconClass, index}) {

  console.log(index)
  return (
    <motion.li 
    initial={{
      transform: "scale(0) translate(0, 0px)",
      opacity:0
    }} 

    exit={{
      transform: "scale(0) translate(0, 0px)",
      opacity:0
    }} 

    animate={{
      transform: "scale(1) translate(0, 0px)",
      opacity: [0,1,.5,1]
    }}

    whileHover={{
      transform: "scale(1) translate(0, 4px)",
      transition : {
        delay: 0
      }
    }}

    transition={{
      delay: index * 0.2,
      type: "spring",
      damping:10
    }}
  
    >
      <a href={link} target='_blank'>
        <i>{iconClass}</i>{socialName}
      </a>
    </motion.li>
  )
}



function MainHome(){
  return (
    <div className='inline-content'>
      <div className="page-home">
        <motion.h1

        initial={{
          transform: "translateY(64px) scale(0)",
          opacity: 0
        }}

        animate={{
          transform: "translateY(0px) scale(1)",
          opacity : 1
        }}

        transition={{
          duration:"1"
        }}
        
        >ENES KAPLAN</motion.h1>
        <div className="social-media">
          <ul>
            <SocialIcon index="0" link="https://twitter.com/Eneskp3441" socialName="Twitter" iconClass={<AiOutlineTwitter/>}/>
            <SocialIcon index="1" link="https://discordapp.com/users/474558346960044032" socialName="Discord" iconClass={<FaDiscord/>}/>
            <SocialIcon index="2" link="https://eneskp3441.itch.io" socialName="Itch.io" iconClass={<FaItchIo/>}/>
            <SocialIcon index="3" link="https://www.youtube.com/channel/UCeLiZVdYO7b2Q5oCCdLpicQ" socialName="Youtube" iconClass={<AiFillYoutube/>}/>
          </ul>
        </div>
      </div>
    </div>
    )
}

export default MainHome