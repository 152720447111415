import React from 'react'
import { useState} from "react";
import { CiShare1 } from "react-icons/ci";
import { CgGames } from "react-icons/cg";
import { BiTime } from "react-icons/bi";
import contentData from "../Database/content-data.json"
import { motion } from "framer-motion";

function GameCard({cardName, cardLink, cardImage, cardEngine, cardDescription, cardTime}) {
    const [cardDescVisible, setCardDescVisible] = useState(false);
    let where = "";
    if ( cardLink.includes("www.nintendo.com") ){
        where = "ON NINTENDO.COM";
    } else if ( cardLink.includes("www.xbox.com") ){
        where = "ON XBOX.COM"
    } else {
        where = "ON ITCH.IO";
    }
    return(
        <motion.a 
        
        initial={{
            opacity: 1,
            transform: "scale(1) perspective(750px) translate3d(0px, 0px, -250px) rotateX(27deg)"
        }}

        whileInView={{
            opacity:1,
            transform: "scale(1) translate3d(0px, 0px, -250px)"
        }}

        transition={{
            delay: .2,
            damping:10,
            style:"spring"
        }}
        
        href={cardLink} onMouseEnter={() => { setCardDescVisible(true) }} onMouseLeave={() => { setCardDescVisible(false) }} target='_blank' className="games-card">
            <div className='cardImage' style={{backgroundImage: `linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0)), url(${cardImage})`}}></div>
            <div className='cardContent'>
                <div className='card-name'>{cardName} </div>
            </div>

            <div className='CardHover' style={{opacity: cardDescVisible ? 1 : 0 }}>
                <div>
                    {cardDescription}
                    <br/>
                    <p style={{textAlign:"center"}}>
                        <span style={{color:"#ff9677", marginTop:15, whiteSpace: "nowrap", verticalAlign:"-webkit-baseline-middle"}}><i style={{verticalAlign:"sub"}}><CiShare1/></i>{where}</span>
                        <span style={{color:"#77ff8a", marginLeft:15,whiteSpace: "nowrap", marginTop:15, verticalAlign:"-webkit-baseline-middle"}}><i style={{verticalAlign:"sub"}}><CgGames/></i> {cardEngine}</span>
                        { cardTime != null && (
                            <span style={{color:"#ff7777", marginLeft:15, whiteSpace: "nowrap",marginTop:15, verticalAlign:"-webkit-baseline-middle"}}><i style={{verticalAlign:"sub"}}><BiTime/></i> {cardTime}</span>
                            )}
                    </p>
                </div>
                
            </div>
            
        </motion.a>
    )
}

function YoutubeGameCard({cardName, cardLink, cardEngine, cardTime}) {
    const [cardVisible, setCardVisible] = useState(true);
    return(
        <motion.a 
        initial={{
            opacity: 1,
            transform: "scale(1) perspective(750px) translate3d(0px, 0px, -250px) rotateX(27deg)"
        }}

        whileInView={{
            opacity:1,
            transform: "scale(1) translate3d(0px, 0px, -250px)"
        }}

        transition={{
            delay: .2,
            damping:10,
            style:"spring"
        }}
        
        className="games-card">
            <iframe frameBorder="none" onMouseEnter={ () => {setCardVisible(false);}} onMouseLeave={ () => {setCardVisible(true);}} src={"https://www.youtube.com/embed/"+cardLink} title="Fighting Game -  Old Version" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            <div className='cardForeground'  style={{opacity : cardVisible ? 1 : 0 }}></div>
            <div className='cardData' style={{opacity : cardVisible ? 1 : 0 }}>
                {cardName}
                <br/>
                <p style={{textAlign:"center"}}>
                    {/* <span style={{color:"#ff9677", marginTop:15, whiteSpace: "nowrap", verticalAlign:"-webkit-baseline-middle"}}><i style={{verticalAlign:"sub"}}><CiShare1/></i> On Itch.io</span> */}
                    <span style={{color:"#77ff8a", marginLeft:15,whiteSpace: "nowrap", marginTop:15, verticalAlign:"-webkit-baseline-middle"}}><i style={{verticalAlign:"sub"}}><CgGames/></i> {cardEngine}</span>
                    {/* { cardTime ? <span style={{color:"#ff7777", marginLeft:15, whiteSpace: "nowrap",marginTop:15, verticalAlign:"-webkit-baseline-middle"}}><i style={{verticalAlign:"sub"}}><BiTime/></i> {cardTime}</span> : <></>} */}
                </p>
            </div>
        </motion.a>
    )
}


function GamesWidget({children, headImage}) {
    return (
        <div className='games-content'>
            <img className='games-head' src={headImage}/>
            <div className="games-grid">
                {children}
            </div>

        </div>
    )
}


function MainGame(){
    const PublishedGames = contentData[0]['published_games'].map( (data) => {
        return(
            <GameCard key={data.id} cardName={data.name} cardEngine={data.engine} cardDescription={data.description} cardTime={data.cardTime} cardLink={data.link} cardImage={data.image}/>
        )
    } )

    const FreelanceGames = contentData[0]['freelance_projects'].map( (data) => {
        if ( data.link.includes("www.nintendo.com") || data.link.includes("www.xbox.com") ) {
            return(
                <GameCard key={data.id} cardName={data.name} cardEngine={data.engine} cardDescription={data.description} cardTime={data.cardTime} cardLink={data.link} cardImage={data.image}/>
            )
        } else {
            return(
                <YoutubeGameCard key={data.id} cardName={data.name} cardEngine={data.engine} cardTime={data.time} cardLink={data.link}/>
            )
        }
    } )

    const ProjectGames = contentData[0]['Projects'].map( (data) => {
        return(
            <YoutubeGameCard key={data.id} cardName={data.name} cardEngine={data.engine} cardLink={data.link}/>
        )
    } )


  return (
    <div id="published_games" className='inline-content'>
      <div className="page-games">
        <GamesWidget headImage={require("../resources/PUBLISHED_GAMES.png")}>
            {PublishedGames}
        </GamesWidget>

        <GamesWidget headImage={require("../resources/FREELANCE_PROJECTS.png")}>
            {FreelanceGames}
        </GamesWidget>

        
        <GamesWidget headImage={require("../resources/PROJECTS.png")}>
            {ProjectGames}
        </GamesWidget>

      </div>
    </div>
    )
}

export default MainGame